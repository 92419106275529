<template>
  <div
    class="page-outter"
    style="width: 63%"
  >
    <div
      align="center"
      style="margin-top: 60px"
    >
      <img
        class="mall-title"
        v-lazy="require('./images/full_service.png')"
      />
    </div>
    <div class="mall-outter">
      <div
        class="md1"
        align="center"
      >
        <img v-lazy="require('./images/icon_move_in.png')" />
        <span class="mds1">快捷入驻</span>
        <div class="mds2">
          简约入驻流程，极速资质审核 <br />
          入驻即可自动对接配送及快递服务 <br />
          智能化平台接单发货便捷操作 <br />
        </div>
      </div>
      <div
        class="md1"
        align="center"
      >
        <img v-lazy="require('./images/icon_after_sale.png')" />
        <span class="mds1">无忧售后</span>
        <div class="mds2">
          技术支持7天驻店指导 <br />
          7*12小时在线客服支持 <br />
          不间断系统升级迭代 <br />
          为客户提供更优的系统体验 <br />
        </div>
      </div>
      <div
        class="md1"
        align="center"
      >
        <img v-lazy="require('./images/icon_data_docking.png')" />
        <span class="mds1">数据对接</span>
        <div class="mds2">
          与九药云智慧医药零售管理系统打通 <br />
          药店数据一键代入<br />
          同时开放商品库 <br />
          支持新签约客户扫码录入
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "fullService",
};
</script>

<style lang="less" scoped>
.mall-title {
  width: 8.8rem;
}
.mall-outter {
  display: flex;
  justify-content: space-between;
  padding-bottom: 66px;
  .md1 {
    margin-top: 1.2rem;
    padding-top: 1.3rem;
    width: 8.4rem;
    height: 7rem;
    background: #f3f3f3;
    .mds1 {
      display: block;
      font-size: 0.46rem;
      color: #6aa5ff;
      font-weight: 600;
      margin-top: 0.36rem;
    }
    .mds2 {
      font-size: 0.38rem;
      color: #aaaaaa;
      line-height: 1.4;
      margin-top: 10px;
    }
    img {
      display: block;
      width: 1.8rem;
    }
  }
}
</style>